<template>
  <div class="agent-apply-check-detail fadeInRight">
    <div class="title-box">
      <span class="m-title">{{detailTitle}}</span>
      <el-form
        inline
        ref="orderDetailForm"
        class="hook right themed-form search-form"
        style="line-height: 40px"
      >
        <el-form-item label="订单号:">
          <el-input v-model="orderDetailForm.orderNum"></el-input>
        </el-form-item>
        <el-form-item label="套餐名称:">
          <el-input v-model="orderDetailForm.mealName"></el-input>
        </el-form-item>
        <el-form-item label="代理商名称:">
          <search-select
            :optionsMetaAll="agentList"
            v-model="orderDetailForm.agentId2"
          ></search-select>
        </el-form-item>
        <el-form-item label="开始时间:">
          <el-date-picker
            placeholder="请选择时间" style="width: 100%;" 
            v-model="orderDetailForm.startTime"
            type="date"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="结束时间">
          <el-date-picker style="width: 100%;" 
            placeholder="请选择时间"
            v-model="orderDetailForm.endTime"
            type="date"
            value-format="yyyy-MM-dd"
          />
        </el-form-item>
        <el-form-item label="状态:">
          <el-select v-model="orderDetailForm.payStatus">
            <el-option label="全部" value="0"></el-option>
            <el-option label="已支付" value="1"></el-option>
            <el-option label="已退款" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-search"
            type="primary"
            class="themed-button"
            @click="onSearch"
            >搜索查询</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button
            icon="el-icon-download"
            class="themed-button"
            type="info"
            @click="exportExcel"
            >导出Excel</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="content-box">
      <el-table
        ref="table"
        border
        stripe
        :data="tableData"
        tooltip-effect="dark"
        :max-height="maxTableHeight"
      >
        <el-table-column
          width="150px"
          label="所属代理商"
          prop="agentTreeName"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          v-if="loginAgentType==0"
          width="150px"
          label="所属总代理商"
          prop="agentName"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="订单号"
          prop="orderNum"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="iccid"
          prop="iccidMark"
          width="180px"
        ></el-table-column>
        <el-table-column label="接入号" prop="phone"></el-table-column>
        <el-table-column label="套餐名称" prop="mealName"></el-table-column>
        <el-table-column
          label="订单金额"
          prop="orderMoney"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="成本价"
          prop="primePrice"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="返现比例"
          prop="rebateRatio"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          label="退款金额"
          prop="refundFee"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.refundFee">{{
              parseFloat(scope.row.refundFee).toFixed(2)
            }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column
          label="支付状态"
          prop="payStatus"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span v-if="scope.row.payStatus == 1">已支付</span>
            <span v-if="scope.row.payStatus == 2">已支付</span>
            <span v-if="scope.row.payStatus == 4">已退款</span>
          </template>
        </el-table-column>
        <el-table-column label="返利方式" prop="type" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.type == 1">返利比模式</span>
            <span v-if="scope.row.type == 2">销售价模式</span>
            <span v-if="scope.row.type == 0">未设置返利模式</span>
          </template>
        </el-table-column>
        <el-table-column label="无佣金标识" prop="noProfitFlag">
                <template slot-scope="scope">
                   <span v-if="scope.row.noProfitFlag==1" style="color:#f00">无佣金</span>
                   <span v-else-if="scope.row.noProfitFlag==0" style="color:#f00"></span>
                   <span v-else style="color:#f00"></span>
                </template>
            </el-table-column>
        <el-table-column label="返现金额" prop="applyMoney"></el-table-column>
       <el-table-column label="返利期数" prop="rebateTerm">
                 <template slot-scope="scope">
                    <span v-if="scope.row.curTerm">{{scope.row.curTerm+"/"+scope.row.totalTerm}}</span>
                    <span></span>
                </template>
             </el-table-column>
        <el-table-column
          label="应扣返现金额"
          prop="deApplyMoney"
        ></el-table-column>
        <el-table-column label="创建时间" prop="createTime"></el-table-column>
        <el-table-column label="退款时间" prop="refundTime"></el-table-column>
      </el-table>
      <el-pagination
        :current-page.sync="pageNo"
        :page-sizes="[10, 15, 25, 100]"
        :page-size="pageSize"
        background
        layout="total,sizes,prev,pager,next,jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="onPageChange"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import SearchSelect from '@/components/SearchSelect.vue'
import { orderGetAgents, agentRebateDetail,exportDetailed } from "@/api/agentApplyCheck.js";
import {getAgentType} from "@/api/agentManagement.js"
import { messageBox, confirmBox } from "@/utils/common.js";
import resize from "@/mixins/resize.js";
export default {
  name:'agentApplyDetail',
  mixins: [resize],
  components:{SearchSelect},
  data() {
    return {
      loginAgentType:'',
      orderDetailForm:{
          applyId:'',
          agentId:'',
          orderNum:'',
          mealName:'',
          agentId2:'',
          startTime:'',
          endTime:'',
          payStatus:'0'
      },
      agentList:[],
      pageNo: 1,
      pageSize: 15,
      total: 0,
      tableData: [],
    }
  },
  computed:{
    detailTitle(){
      this.title = this.$route.query.title;
      if(this.title){
        return '代理商返利明细'
      }else{
        return '订单详情'
      }
    }
  },
  mounted() {
    this._orderGetAgents()
    this.getAgentType()
    this.applyId = this.$route.query.applyId;
    this.agentId = this.$route.query.agentId;
    this.orderDetailForm.applyId = this.$route.query.applyId?this.$route.query.applyId:'';
    this.orderDetailForm.agentId = this.$route.query.agentId?this.$route.query.agentId:'';
    this.orderDetailForm.startTime = this.$route.query.startTime?this.$route.query.startTime:'';
    this.orderDetailForm.endTime = this.$route.query.endTime?this.$route.query.endTime:'';
    this._agentRebateDetail();
  },
  methods: {
    getAgentType(){
				getAgentType().then(res=>{
					this.loginAgentType = res.agentType
					this.nickName = res.nickName
				})
      },
    
    exportExcel(){
        exportDetailed(this.orderDetailForm).then(res=>{
            if (res.system_result_key == '0') {
                this.$alert('导出成功，请到导出记录中下载', '提示', {
                    confirmButtonText:'确定',
                }).then(()=>{
                    this.$router.push('/tob/i_export_list')
                });
            }else{
                this.$message({
                    showClose: true,
                    message: res.system_result_message_key,
                    type: 'error'
                });
            }
        })
    },
    onSearch(){
        this._agentRebateDetail();
    },
    onPageChange(page) {
      this.pageNo = page;
      this._agentRebateDetail();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this._agentRebateDetail();
    },
    _orderGetAgents() {
      this.agentList = []
      this.agentList.push({
            label:'全部',
            value:''
        })
      this.agentList.push({
        label: "当前账号",
        value: "-1",
      })
      orderGetAgents({ status: 0 }).then((res) => {
        if (res.length > 0) {
          res.forEach((item) => {
            this.agentList.push({
              label: item.name,
              value: item.id,
            })
          })
        }
      });
    },
    _agentRebateDetail(){
        let params = {
            applyId:this.applyId,
            agentId:this.agentId,
            pageNo:this.pageNo,
            pageSize:this.pageSize
        }
        agentRebateDetail(Object.assign(params,this.orderDetailForm)).then((res)=>{
            this.pageNo = res.pageNo
            this.pageSize = res.pageSize
            this.tableData = res.rows
            this.total = res.total
        })
    }
  },
}
</script>
<style>
    .agent-apply-check-detail{position:relative;}
    .agent-apply-check-detail .el-table .cell{height:23px;}
</style>